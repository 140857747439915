@import "./components/pages/startPage/startPage.scss";
@import "./components/pages/AboutUs/aboutUs.scss";
@import "./components/pages/portfolio/portfolio.scss";

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}
body {
  -ms-overflow-style: none;  /* IE и Edge */
  scrollbar-width: none;  /* Firefox */
}

a {
  text-decoration: none;
  margin: 0
}

:root {
  //color
  --orange: #FA541B;
  --gray: #6d7786;
  --black: #212b36;
  --white-blue: #d6f2fc;

  //transition
  --transition: all ease .3s;

  //font-size
  --title: 3.625rem;
  --subtitle: 1rem;
  --standart: 0.875rem;

  //font-weight
  --title-weigth: 700;
  --subtitle-weight: 400;
  --button: 600;

  //box-shadow
  --shadow-standart: 0 2px 2px 2px rgb(255, 242, 242);

}

h1, h2, h3, h4, h5, h6, p {
  padding: 0;
  margin: 0;
}

.cursor__pointer{
  cursor:pointer
}

.box__shadow {
  background-color: inherit;
  box-shadow: var(--shadow-standart)
}
.text-shadow{
  text-shadow: 0 2px 2px #00000066;
}