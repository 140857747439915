@media(max-width: 1630px) {
  .box__cards {
    max-width: 600px !important;
  }
  .main__title {
    font-size: 3rem !important;
    line-height: 1.3 !important;
  }
  .main__logo {
    line-height: 1.3 !important;
    font-size: 3rem !important;
  }
}

@media (max-width: 1280px) {
  .main__box {
    text-align: center !important;
  }
  .about__line {
    max-height: 250px !important;
  }
  .team__prev {
    display: none !important;
  }
  .team__next {
    display: none !important;

  }
}

@media (max-width: 1168px) {
  .main__box {
    text-align: center !important;
  }
}

@media (max-width: 1047px) {
  .team__container {
    flex-direction: column !important;
    margin: 0 auto;
  }
}

@media(max-width: 1020px) {
  #navigation_line {
    visibility: hidden;
  }
  .introduction__title {
    padding: 10px !important;
  }
  .header {
    height: 76px !important;
  }
  .header--active {
    height: 50px !important;
  }
  .arrow_prev {
    display: none !important;
  }
  .arrow_next {
    display: none !important;
  }

  .block {
    &__left--small {
      width: 127px !important;
      height: 127px !important;
      border-radius: 10px !important;
      border-top-left-radius: 80px !important;

    }

    &__right--small {
      height: 127px !important;
      width: 127px !important;
      border-radius: 10px !important;
      border-bottom-right-radius: 80px !important;
    }

    &__right--big {
      width: 362px;
      border-radius: 10px !important;

    }

    &__left--big {
      width: 362px;
      border-radius: 10px !important;

    }
  }
  .our__team {
    display: flex !important;
    justify-content: center;
  }
  .team__background {
    margin: 0 0 10px 0 !important;
  }
  .team__container {
    gap: 0 !important;
  }
}


@media(max-width: 800px) {
  .line__wrapper {
    right: -25px !important;
  }
  .introduction__wrapper {
    margin: 10px auto !important;
  }
  #testimonials {
    padding: 10px 0 10px 0 !important;
  }
  #stack {
    padding: 10px 0 10px 0 !important;

  }
}

@media(max-width: 660px) {
  .main__title {
    font-size: 2rem !important;
  }
  .box__shadow {
    padding: 5px 10px !important;
  }
  .main__logo {
    line-height: 1.3 !important;
    font-size: 2rem !important;
  }
  .box__cards {
    max-width: 400px !important;
  }
  .title {
    font-size: 1.6rem !important
  }
  .subtitle {
    font-size: 0.7rem !important;
  }
  .contacts {
    font-size: 2rem !important;
  }
  .footer__title {
    font-size: 0.7rem !important
  }
  .introduction__wrapper {
    column-gap: 10px;
    row-gap: 5px;
  }
  .block {
    &__left--small {
      width: 100px !important;
      height: 100px !important;
      border-radius: 10px !important;
      border-top-left-radius: 80px !important;

    }

    &__right--small {
      height: 100px !important;
      width: 100px !important;
      border-radius: 10px !important;
      border-bottom-right-radius: 80px !important;
    }

    &__right--big {
      width: 286px;
      border-radius: 10px !important;

    }

    &__left--big {
      width: 286px;
      border-radius: 10px !important;

    }
  }
}

@media (max-width: 600px) {
  .carousel{
    display: none;
  }
}

@media(max-width: 440px) {
  .panel {
    padding: 10px !important;
  }
  .line__wrapper {
    right: -15px !important;
  }
  .main__title {
    font-size: 1.3rem !important;
  }
  .main__logo {
    line-height: 1.3 !important;
    font-size: 1rem !important;
  }
  .box__cards {
    max-width: 200px !important;
  }
  .title {
    font-size: 1.3rem !important
  }
  .subtitle {
    font-size: 0.7rem !important;
  }
  .contacts {
    font-size: 1.3rem !important;
  }
  .block {
    &__left--small {
      width: 70px !important;
      height: 70px !important;
      border-top-left-radius: 40px !important;

    }

    &__right--small {
      height: 68px !important;
      width: 68px !important;
      border-bottom-right-radius: 40px !important;

    }

    &__right--big {
      width: 200px;
    }

    &__left--big {
      width: 200px;
    }
  }
  .introduction__title {
    padding: 5px !important;
    font-size: 0.7rem !important;
  }
  .introduction__wrapper {
    row-gap: 5px !important;
    column-gap: 10px !important;
  }
}

@media (max-width: 312px) {
  .block {
    &__left--small {
      width: 60px !important;
      height: 60px !important;
    }

    &__right--small {
      height: 60px !important;
      width: 60px !important;
    }

    &__right--big {
      width: 172px;
    }

    &__left--big {
      width: 172px;
    }
  }
}