@media (max-width: 1270px) {
    .history__subtitle{
        max-width: 400px!important;
    }
    .history__subtitle h6{
        font-size: 0.7rem;
    }
    .history__title h6{
        font-size: 1rem;
    }
}
@media (max-width: 900px) {
    .history__subtitle{
        max-width: 300px!important;
    }

}
@media (max-width: 900px) {
    .history__subtitle{
        max-width: 300px!important;
    }

}

@media (max-width: 660px) {
    .history__subtitle{
        max-width: 250px!important;
    }
    .history__wrapper{
        margin-bottom: 20px!important;
    }
}

@media (max-width: 570px) {
    .history__subtitle{
        max-width: 200px!important;
    }
    .history__subtitle h6{
        font-size: 0.5rem;
    }
    .history__title h6{
        font-size: 0.7rem;
        margin-bottom: 5px;
    }
    .history__year h6{
        font-size: 0.7rem!important;
    }
    .history__line{
        width: 6px;
    }
    .history__circle{
        width: 15px;
        height: 15px;
    }
}

@media (max-width: 480px) {
    .history__subtitle{
        max-width: 150px!important;
    }
}

@media (max-width: 360px) {
    .history__subtitle{
        max-width: 100px!important;
    }
    .history__subtitle h6{
        line-height: 1.2;
    }
}
