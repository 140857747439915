.history {
  &__line {
    position: relative;
    width: 6px;
    height: 150px;
    top: 0;
    bottom: 0;
    background: var(--orange);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    filter: blur(1px);
  }

  &__circle {
    position: relative;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: var(--orange) !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__title {
    white-space: nowrap;
  }

  &__subtitle {
    width: max-content;
    max-width: 600px;
  }

  &__wrapper {
    margin-bottom: 50px;
  }
}

.history__title h6 {
  font-weight: 600 !important;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.history__subtitle h6 {
  font-weight: 600 !important;
  font-size: 0.9rem;
  color: var(--gray)
}

.history__year--left {
  text-align: right;
}

.history__info--left {
  text-align: right;
}

.history__box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 35px;
  top: 0;
}

.history__box--left {
  align-items: flex-end;
  left: auto;
  right: 35px;
}

.why__block {
  position: relative;
  transition: var(--transition);
}

.why__block:hover {
  transition: var(--transition);
  transform: translate(10px);
}

.why__block:hover .why__title {
  transition: var(--transition);
  color: var(--orange)
}

.why__block:hover:after {
  display: block;
  content: ' ';
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: var(--orange);
  transition: var(--transition);
  left: -20px;
  top: 0
}

.background__services {
  background: url("./assets/background.png");
  background-repeat: no-repeat;
  background-size: cover;
}


@import "aboutUs.media";