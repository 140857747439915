.wrapper {
  width: 100%;
}

.box__shadow {
  background-color: inherit;
  box-shadow: var(--shadow-standart)
}

.header {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  height: 96px;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 101;
  backdrop-filter: blur(5px);
  transition: var(--transition);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.navigation__title {
  opacity: 0;
}

.navigation__underline:hover .navigation__title {
  opacity: 100;
}

.header--active {
  height: 76px;
  box-shadow: 0 3px 12px 6px rgba(0, 0, 0, 0.02);
  transition: all .4s linear;

}

.carousel-status {
  display: none;
}

.dot {
  width: 8px !important;
  height: 8px !important;
  background-color: var(--orange) !important;
}

.control-dots {
  position: static !important;
}

.carousel.carousel-slider {
  display: flex;
  flex-direction: column-reverse;
  overflow: visible !important;
}

.control-arrow {
  border-radius: 50% !important;
  background: black !important;
  top: 45% !important;
  width: 20px !important;
  height: 20px !important;
}

.control-arrow:before {
  display: block !important;
}

.arrow_prev {
  position: absolute !important;
  top: 50% !important;
  left: -30px !important;
  z-index: 100 !important;
  transform: translate(-50%, -80%);

}

.arrow_next {
  position: absolute !important;
  top: 50% !important;
  right: -85px !important;
  z-index: 100 !important;
  transform: translate(-50%, -80%);

}

.carousel-root {
  cursor: pointer !important;
}

.box {
  &__cards {
    max-width: 800px;
    transition: var(--transition);
    animation: cards infinite 3s linear;
  }

  &__img {
    width: 100%;
  }
}

.main__cards img {
  width: 100%;
  object-fit: cover;
}


.main__cards-animation {
  @keyframes cards {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 10px);
    }
    100% {
      transform: translate(0, 0px);

    }
  }
}


.contact_us {
  background: linear-gradient(to right, rgba(22, 28, 36, 0) 0%, rgba(22, 28, 36, 1) 50%), url(https://zone-assets-api.vercel.app/assets/images/marketing/marketing_get_free_seo.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.background__testimonials {
  background: #212B36
}

.input_text {
  color: white !important;
  background-color: #212B36;
  border-radius: 6px;
  border: 1px solid #212B36 !important;
}


.developer_block {
  position: relative;
  max-width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: max-content;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(15px);
  transition: var(--transition);
  padding: 20px;
  border: 1px solid white;
}

.stack_line {
  position: absolute;
  height: 50px;
  background: linear-gradient(90deg, rgba(250, 84, 16, 1) 0%, rgba(255, 214, 0, 1) 50%);
  right: 0;
  left: 0;
  top: 50%;
  width: 100%;
  transition: var(--transition);
  overflow: hidden;
  box-shadow: 0 3px 3px 3px rgba(211, 211, 211, 0.37);
}


.stack_line--animation {
  @keyframes line {
    0% {
      translate: 0;
    }
    25% {
      translate: 300%;
    }
    50% {
      translate: 0;
    }
    75% {
      translate: -300%;
    }
    100% {
      translate: 0;
    }

  }
}

.about__line {
  box-shadow: 0 2px 5px 2px lightgray;
  max-height: 400px;
  border-radius: 6px;
  top: 0;
  background-color: var(--orange);
  filter: blur(1px);
}

.about__block__text {
  max-width: 600px
}

.team__prev {
  width: 50px;
  position: absolute !important;
  left: -225px;
  top: 50%;

}

.team__next {
  position: absolute !important;
  left: -145px;
  top: 50%;
  width: 50px;

}

.navigation {
  position: relative;
  opacity: 0;
  transition: var(--transition);

  &__underline {
    position: fixed;
    display: flex;
    top: 20%;
    left: 20px;
    width: 10px;
    height: 500px;
    background-color: #6d778617;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

  }

  &__line {
    position: absolute;
    width: 10px;
    background: var(--orange);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    filter: blur(1px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &__ellipse {
    position: absolute;
    left: -4px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1000;
    background: lightgray;
    cursor: pointer;
    transition: all .2s linear;
  }

  &__title {
    left: 30px;
    max-width: 100px;
    white-space: nowrap;
    transform: rotate(-30deg);
    font-size: 1rem;
    font-weight: 600;
    line-height: 15px;
    color: #000000;
    pointer-events: none;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    transition: all .2s linear
  }
}

.navigation--active {
  opacity: 100%;
  transition: var(--transition);
}

.navigation__ellipse:hover {
  scale: 1.2;
  transition: all .2s linear;
}


.navigation__ellipse--active {
  transition: var(--transition);
  animation: ellipse 1 .4s linear;
  background: var(--orange) !important;
}

.navigation__ellipse--animation {
  @keyframes ellipse {
    0% {
      translate: 0 -2px;
    }
    33% {
      translate: 0 0;
    }
    66% {
      translate: 0 -2px;
    }
    100% {
      translate: 0 0;
    }
  }

}

.navigation__ellipse:hover + .navigation__title {
  color: var(--orange);
  transition: all .2s linear;
}

.navigation__title--active {
  transition: all .2s linear;
  color: var(--orange);
}

.introduction {
  &__img {
    width: 100%;
  }

  &__title {
    position: absolute;
    width: max-content;
    top: -10px;
    left: -10px;
    height: max-content;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    transition: var(--transition);
    box-shadow: 0 2px 6px -8px rgba(0, 0, 0, 0.27);
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    color: white;

  }
}


.block {
  &__right--small {
    overflow: hidden;
    border-radius: 20px;
    border-bottom-right-radius: 100px !important;
  }

  &__right--big {
    overflow: hidden;
    border-radius: 20px;
  }

  &__left--small {
    overflow: hidden;
    border-radius: 20px;
    border-top-left-radius: 100px !important;
  }

  &__left--big {
    overflow: hidden;
    border-radius: 20px;
  }
}

.team__background {
  background: white
}

.contacts {
  font-size: 3.625rem;
  font-weight: 700;
  color: var(--orange);
  line-height: 1 !important;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.t-shadow {
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.portfolio {
  &__image {
    cursor: pointer;
    width: 100%;
    height: 100%;

  }

  &__about {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(5px);
    transition: all .3s linear;

    pointer-events: none;
  }
}

.project__mobile {
  border-radius: 8px;
  border: 1px solid white;

}

.project__description {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
  transition: var(--transition);
  box-shadow: 0 2px 6px -8px rgba(255, 255, 255, 0.37
  );
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  cursor: pointer
}

.panel {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
  transition: var(--transition);
  box-shadow: 0 2px 6px -8px rgba(255, 255, 255, 0.37);
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;

  &__title {
    position: absolute;
    display: block;
    top: 0;
    left: -10px;
    content: '';
    width: 3px;
    height: 100%;
    border-radius: 1px;
    background: var(--orange);
  }
}

.line__wrapper {
  position: absolute;
  width: 5px;
  right: -60px;
  background: #6d778617;
  border-radius: 6px;
  top: 0;
  bottom: -20px
}

.introduction__wrapper {
  display: flex;
  justify-content: center;
  margin: 50px auto 50px auto;
  max-width: 890px;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 20px;
}

#testimonials {
  padding: 50px 0 50px 0;
  width: 100%;
}

#stack {
  position: relative;
  overflow: hidden;
  padding: 50px 0 50px 0;
}

.subtitle {
  font-size: 0.9rem;
  color: var(--gray);
  font-family: "Montserrat", sans-serif;
}

.title {
  font-weight: 600 !important;
  font-size: 2.75rem;
}

.our__team {
  flex-wrap: wrap;
}

.our__team__img {
  width: 100%;
  border-radius: 8px;
}

.team__container {
  width: 100%;
  flex-direction: column
}

.undo {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50% !important;
  background-color: var(--orange) !important;
  box-shadow: 0 5px 12px rgb(0, 0, 0, 0.4) !important;
  width: max-content;
  height: max-content;
  z-index: 1000;
}

.stack_h {
  font-size: 2rem;
  font-weight: 600;
  color: #212b36;
  margin-bottom: 0.75rem;
}

.stack_icon {
  width: 2rem;
  height: 2rem;
  aspect-ratio: 1 !important;
  box-shadow: 0 2px 2px 2px rgba(211, 211, 211, 0.17);

  border: 1px solid white;
  border-radius: 8px;
  cursor: pointer;
  padding: 6px;
}

.stack_icon-wide {
  width: 5rem;
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 8px;

  padding: 8px;
}

.project_card__desc {
  cursor: pointer;
  width: 100%;
  max-width: 1254px;
  padding-block: 1rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;
  border-radius: 8px
}

.project_card__desc__img {
  width: 100%;
  height: 30vh;
  border-bottom: 1px solid black;
}

.project_card__mob {
  width: 100%;
  position: relative;
  box-shadow: 0 0 15px 3px rgba(157, 157, 157, 0.48);
}

@media screen and (min-width: 1350px) {
  .project_card__desc__img {
    //width: 70%;
  }
  //.project_card__mob {
  //  width: 30% !important;
  //}
}

@media screen and (min-width: 1100px) {
  .project_card__desc {
    flex-direction: row;
    align-items: center;
    text-align: left;
  }
  .project_card__desc__img {
    width: 100%;
    height: 100%;
    border-bottom: 0;
  }
  .project_card__desc img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    object-position: center !important;
  }
}

@media screen and (min-width: 825px) {
  .project_card__mob {
    width: 47%;
  }

}

.project_card__desc img {
  width: 100%;
  height: 100%;
  object-position: top;
  object-fit: cover;
}

.project_card__mob img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.navigation__link {
  text-decoration: none;
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  transition: var(--transition);

}

.navigation__link--active {
  color: var(--orange) !important;
  transition: var(--transition);
}

.breadcrumbs {
  color: black
}

.breadcrumbs:hover {
  text-decoration: underline;
}

.carousel__portfolio .carousel .thumb.selected {
  border-radius: 5px !important;
  border: 1px solid var(--orange) !important;
  box-shadow: 0 5px 12px rgb(100 116 139 / 12%) !important;
}
.carousel__portfolio--media .carousel .thumbs-wrapper{
  display: flex;
  justify-content: center;
  padding: 0!important;
}


.selected__portfolio .carousel .thumb.selected, .carousel .thumb:hover {
  border-radius: 5px !important;
  border: 1px solid #fff !important;
  box-shadow: 0 5px 12px rgb(100 116 139 / 12%) !important;
}
.button__render--left{
  position: absolute!important;
  top:45%;
  z-index: 10;
  left: -10px;
  box-shadow: 0 5px 12px rgb(100 116 139 / 12%) !important;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
  transition: var(--transition);

}

.button__render--right{
  position: absolute!important;
  top:45%;
  z-index: 10;
  right: -10px;
  box-shadow: 0 5px 12px rgb(100 116 139 / 12%) !important;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
  transition: var(--transition);
}

@import "./startPage.media";